import React, { useState, useEffect, useCallback } from "react"

import Fonts from "../components/fonts"
import SEO from "../components/seo"

import useDistance from '../hooks/useDistanceToCenter'

import tealPalete from '../tealPalete';
import useOrientation from "../hooks/useOrientation";
import './index.css'


const IndexPage = () => {
  const [color, setColor] = useState('#eff7f7');
  const handleEvents = useCallback(factor => setColor(tealPalete(factor)))

  useDistance(handleEvents)
  useOrientation(handleEvents)

  const requestPermission = () => {
    if (typeof DeviceOrientationEvent == 'undefined') return
    if (typeof DeviceOrientationEvent.requestPermission == 'undefined') return
    DeviceOrientationEvent.requestPermission()
  }

  return (  
    <div className="center" onClick={requestPermission} style={{ backgroundColor: color }}>
    <SEO title="Peter" />
    <Fonts />
    <h1>Peter</h1>
    <h1 className="blinking">_</h1>
  </div>
)}

export default IndexPage
