import { useEffect, useCallback, useState } from 'react';

function twoPointDistance(x1, x2, y1, y2) {
  return Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2)
}

function normalize(distance) {
  return 2 * distance / Math.sqrt(window.innerWidth ** 2 + window.innerHeight ** 2)
}

export default callback => {
  const calculate = useCallback(({ clientX, clientY }) => {

    callback(normalize(twoPointDistance(clientX, window.innerWidth / 2, clientY, window.innerHeight / 2)))
  }, [callback])

  useEffect(() => {
    document.addEventListener('mousemove', calculate)
    return () => document.removeEventListener('mousemove', calculate)
  }, [])
}
