/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import './fonts.css'

const Fonts = () => (
    <Helmet>
      <link href="https://fonts.googleapis.com/css?family=Cutive+Mono&display=swap" rel="stylesheet" /> 
    </Helmet>
  )


export default Fonts
