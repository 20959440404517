const tintGB = factor => 128 + (255 - 128) * factor
const shadeGB = factor => 128 * (1 - factor)

const tintR = factor => 255 * factor

export default number => {
  if (number > 0.5) {
    const factor = 2 * number - 1
    const green = tintGB(factor)
    const blue = tintGB(factor)
    const red = tintR(factor)
    return `rgb(${red}, ${green}, ${blue})`
  } else {
    const factor = -2 * number + 1
    const green = shadeGB(factor)
    const blue = shadeGB(factor)
    return `rgb(0, ${green}, ${blue})`
  }
} 
