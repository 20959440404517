import { useEffect, useCallback, useState } from 'react';


function normalize(rotation) {
  if (rotation < 0) return 0;
  if (rotation > 90) return 1
  return rotation / 90
}

export default callback => {
  const calculate = useCallback(({ beta }) => {
    callback(normalize(beta))
  }, [callback])

  useEffect(() => {
    window.addEventListener('deviceorientation', calculate)
    return () => window.removeEventListener('deviceorientation', calculate)
  }, [])
}
 